exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-course-page-tsx": () => import("./../../../src/pages/CoursePage.tsx" /* webpackChunkName: "component---src-pages-course-page-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/Datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/Home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/Impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-statement-page-tsx": () => import("./../../../src/pages/StatementPage.tsx" /* webpackChunkName: "component---src-pages-statement-page-tsx" */),
  "component---src-pages-studien-page-tsx": () => import("./../../../src/pages/StudienPage.tsx" /* webpackChunkName: "component---src-pages-studien-page-tsx" */),
  "component---src-pages-treatment-page-tsx": () => import("./../../../src/pages/TreatmentPage.tsx" /* webpackChunkName: "component---src-pages-treatment-page-tsx" */)
}

